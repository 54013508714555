import { onUnmounted } from 'vue';
<template>
  <Teleport to="body">
    <div
      :id="id"
      ref="rootElement"
      class="dialog-container"
      :role="roleAttribute"
      aria-hidden="true"
      :aria-labelledby="titleId"
    >
      <div
        :data-a11y-dialog-hide="isAlert ? undefined : true"
        tabIndex="-1"
        class="dialog-overlay"
        @click="isAlert ? undefined : close"
      />
      <div
        role="document"
        class="dialog-document"
        :class="{ [`dialog-document-${type}`]: true }"
      >
        <div v-if="processing" class="dialog-processing">
          <LoadingSpinner />
        </div>

        <div class="dialog-header">
          <p
            :id="titleId"
            class="dialog-title"
            :class="{ 'visually-hidden': type === 'media' }"
          >
            {{ title }}
          </p>

          <button
            v-if="type !== 'alert'"
            data-a11y-dialog-hide
            :aria-label="$t('common:clickToClose')"
            class="dialog-close button-plain"
            type="button"
            @click="close"
          >
            <RiCloseLine width="24" height="24" />
          </button>
        </div>

        <div class="dialog-content">
          <slot />
        </div>

        <div
          v-if="$slots.actionsLeft || $slots.actionsRight || cancel"
          class="dialog-actions"
        >
          <div class="dialog-actions-section">
            <slot name="actionsLeft"> </slot>
          </div>
          <div class="dialog-actions-section">
            <button
              v-if="cancel"
              class="button grey"
              type="button"
              @click="close"
            >
              {{ $t('common:cancel') }}
            </button>
            <slot name="actionsRight"> </slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import A11yDialog from 'a11y-dialog'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { RiCloseLine } from 'vue-remix-icons'
import Teleport from 'vue2-teleport'

export default {
  name: 'BaseDialog',
  components: {
    LoadingSpinner,
    RiCloseLine,
    Teleport,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'form',
      validator(value) {
        return ['alert', 'form', 'media'].includes(value)
      },
    },
  },
  emits: ['hide', 'show'],
  setup(props, { emit }) {
    let dialog
    const rootElement = ref(null)

    const titleId = computed(() => {
      return `${props.id}-title`
    })

    const isAlert = computed(() => {
      return props.type === 'alert'
    })

    const roleAttribute = computed(() => {
      return isAlert.value ? 'alertdialog' : 'dialog'
    })

    const close = () => {
      dialog.hide()
    }

    const onShow = () => {
      emit('show')
      document.body.classList.add('lock-scroll')
    }

    const onHide = () => {
      emit('hide')
      document.body.classList.remove('lock-scroll')
    }

    onMounted(() => {
      if (rootElement.value != null) {
        dialog = new A11yDialog(rootElement.value)
        dialog.on('show', onShow)
        dialog.on('hide', onHide)

        if (props.active) {
          dialog.show()
        }
      }
    })

    onUnmounted(() => {
      if (dialog) {
        dialog.off('show', onShow)
        dialog.off('hide', onHide)
        dialog.destroy()
      }
    })

    watch(
      () => props.active,
      (value, prevSelection) => {
        if (dialog) {
          if (value) {
            dialog.show()
          } else {
            dialog.hide()
          }
        }
      }
    )

    return {
      close,
      dialog,
      isAlert,
      roleAttribute,
      rootElement,
      titleId,
    }
  },
}
</script>

<style lang="less" scoped>
@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(1rem);
  }
}

.dialog-container {
  display: flex;
  z-index: var(--layer-dialog);
}

.dialog-overlay {
  animation: fade-in 0.2s both;
  background-color: var(--color-black-fade50);
}

.dialog-overlay,
.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.dialog-container[aria-hidden='true'] {
  display: none;
}

.dialog-document {
  animation: fade-in 0.2s 0.1s both, slide-up 0.2s 0.1s both;
  background-color: var(--color-white);
  border-radius: var(--radius-md);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: auto;
  z-index: 2;
  position: relative;
  min-width: 20rem;
  max-height: calc(100vh - 4rem);
}

.dialog-document-alert {
  width: 28rem;
}

.dialog-document-form {
  width: 37.5rem;
}

.dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  z-index: 1;

  & p {
    margin: 0;
  }

  .dialog-document-alert & {
    padding-bottom: 0.75rem;
  }

  .dialog-document-form & {
    border-bottom: 1px solid var(--color-grey-medium);
  }

  .dialog-document-media & {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.dialog-title {
  font-weight: var(--font-weight-bd);
}

.dialog-close {
  border-radius: var(--radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  margin-left: auto;
  position: relative;
  transform: translateX(1rem);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 2.75rem;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  &:focus-visible {
    outline: 2px solid var(--color-brand);
  }
}

.dialog-content {
  position: relative;
  overflow: auto;

  .dialog-document-alert & {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .dialog-document-form & {
    padding: 1.5rem;
  }

  .dialog-document-media & {
    min-height: 15rem;
  }
}

.dialog-processing {
  background-color: var(--color-white-fade80);
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.dialog-actions {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 1.5rem;

  .dialog-document-form & {
    border-top: 1px solid var(--color-grey-medium);
  }
}

.dialog-actions-section {
  display: flex;
  gap: 0.75rem;
}
</style>

<style lang="less">
.dialog-content {
  & img {
    display: block;
    height: auto;
    max-width: calc(100vw - 4rem);
    max-height: calc(100vh - 4rem - 5.5rem);
  }

  & p {
    margin: 0;
  }

  & > * + * {
    margin-top: 1.5rem;
  }
}
</style>
